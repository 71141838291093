import { getUserLevel } from "../../context/authroute";

export async function saveInputs(
  event,
  recordType,
  projectName,
  notProjectSpecific
) {
  // if notProjectSpecific is true, return all records of the required type (not just those associated with this project)
  // TODO: make notProjectSpecific an easily toggleable selection

  event.preventDefault();
  // Data to save is: user, project name, gear name, calculator code, calculator version, input string, creation time, update time
  //

  // Need to collect the data for each of these points
  var saveProject = projectName; // passed as parameter from calling function
  var saveGear;

  var saveCalcVersion = "0";
  var saveString = {};

  // Collect gear name
  if (recordType === "single" || recordType === "machine") {
    saveGear = document.getElementById("input-gearname").value;
    saveGear = saveGear ? saveGear : "default";
  }
  if (recordType === "hob") {
    saveGear = document.getElementById("input-hobname").value;
    saveGear = saveGear ? saveGear : "default";
  }
  // Format gear saved name
  var saveRecordName = saveGear; // bypass formatting for new userdata storage
  // if (recordType === 'single') {
  //   saveRecordName = 'gear-' + saveGear;
  // }
  // else if (recordType === 'machine') {
  //   saveRecordName = 'machine-' + saveGear
  // }
  // else {
  //   saveRecordName = 'unknown-' + saveGear;
  // }

  var existingData, existingInputs, existingDataFilter, existingGearData;
  try {
    // Check if there is an existing record, and if so, pull in its data
    existingData = localStorage.getItem("userdata"); // change for new userdata storage
    existingData = JSON.parse(existingData);

    existingDataFilter = existingData.filter((object) => {
      // Filter to just the data that matches the gear name, type and project

      return (
        object.gear == saveRecordName &&
        object.recordtype == recordType &&
        (object.project == projectName || notProjectSpecific)
      ); // ID could be stored as String or Int!
    });

    existingGearData = existingDataFilter[0]; // Data for the 1 gear that matches the current gear
    existingInputs = existingGearData.inputstring;
    if (!existingInputs) existingInputs = {}; // set to an empty object if it's otherwise falsy
    if (!existingData) existingData = []; // set to an empty array if falsy
  } catch {
    localStorage.setItem("userdata", JSON.stringify([]));
    existingGearData = {};
    existingInputs = {};
  }
  // TODO: get username and JWT and send the pair for server validation
  // TODO_SECURE
  // Get the username and idToken. Both will be sent and username will be matched against verified JWT on the server
  var tempUserInfo, tempUsername, idToken, refreshToken;
  try {
    // Check if there is an existing record, and if so, pull in its data
    tempUserInfo = localStorage.getItem("currentUser");
    tempUserInfo = JSON.parse(tempUserInfo);
    tempUsername = tempUserInfo.username;
    idToken = tempUserInfo.idToken;
    refreshToken = tempUserInfo.refreshToken;
  } catch {
    tempUsername = "suspectuser";
    idToken = "";
    refreshToken = "";
  }

  // TODO: Fix (better handling for default project)
  if (!saveProject || saveProject === "default")
    saveProject = "default-" + tempUsername;

  // Collect user input
  // Only when the inputs exist! (otherwise it throws an error getting 'value' from undefined)
  // If the input form doesn't exist, copy any existing value

  // Only collect information related to the recordType (machine, gear, hob)
  // This way, calculators that use multiple types (F&S has gear and hob) shouldn't overwrite each other
  if (recordType === "single") {
    saveString.unitsMM = document.getElementsByName("units")[0]
      ? document.getElementsByName("units")[0].checked
      : existingInputs.unitsMM; // (true is mm)
    saveString.typeExt = document.getElementsByName("input-type")[0]
      ? document.getElementsByName("input-type")[0].checked
      : existingInputs.typeExt; // (true is external)

      let gearParams = [
        'numteeth',
        'module',
        'pangle',
        'helical',
        'shift',
        'pindia',
        'span',
        'face',
        'pangdrop',       // Lewis, Dudley
        'I',              // Lewis
        'sacdrop',        // Lewis
        'materialdrop',   // Lewis
        'Sy',             // Lewis
        'Se',             // Lewis
        'matldrop',       // Hob F&S
        'hardness',       // Hob F&S
        'machinability',  // Hob F&S
        'basespeed',      // Hob F&S
        'scallop',        // Hob F&S
        'thinning',       // I/J calc
        'tooltipradius',  // I/J calc
        'numteethgear',   // I/J calc
        'ratio',          // Planetary
        'planets',        // Planetary
      ];
        for (let i=0;i<gearParams.length;i++){
          saveString[gearParams[i]] = document.getElementById("input-" + gearParams[i])
          ? document.getElementById("input-"+gearParams[i]).value
          : existingInputs[gearParams[i]];
        }

    saveString.precision = document.getElementById("precision")
      ? parseFloat(document.getElementById("precision").value)
      : existingInputs.precision;


  }

  if (recordType === "machine") {
    // Gear3 Machine specific
    // saveString.cgears = document.getElementById("input-cgears")
    //   ? document.getElementById("input-cgears").value
    //   : existingInputs.cgears;
    let machineParams = [
      'manufacturer',
      'model',
      'mfgdate',
      'serial',
      'maxmodule',
      'minmodule',
      'maxdiaspur',
      'maxfacespur',
      'mintooth',
      'maxhobspeed',
      'minhobspeed',
      'maxfeedlong',
      'minfeedlong',
      'maxdiahob',
      'cgears',
      'cgearsfeed',
      'cgearshelical',
      'mcindex',
      'mcfeed',
      'mchelical',
    ];
      for (let i=0;i<machineParams.length;i++){
        saveString[machineParams[i]] = document.getElementById("input-" + machineParams[i])
        ? document.getElementById("input-"+machineParams[i]).value
        : existingInputs[machineParams[i]];
      }
  }

  if (recordType === "hob") {

    let hobParams = [
      'module',
      'hobdia',      
      'hobmatldrop',
      'hobcoatindrop',
      'hobkfactor',
      'coatingkfactor',

    ];
      for (let i=0;i<hobParams.length;i++){
        saveString[hobParams[i]] = document.getElementById("input-" + hobParams[i])
        ? document.getElementById("input-"+hobParams[i]).value
        : existingInputs[hobParams[i]];
      }

    // Hob Feed & Speed (model 1)
    saveString.unitsMM = document.getElementsByName("units")[0]
      ? document.getElementsByName("units")[0].checked
      : existingInputs.unitsMM; // (true is mm)
  }

  if (saveString.pangdrop && !saveString.pangle)
    saveString.pangle = saveString.pangdrop; // if it's coming from Lewis, fill in the value

  // Creation time, only if it doesn't already exist
  var currentTime = Date.now();
  var saveCreationTime;
  try {
    saveCreationTime = existingGearData.creationtime;
    if (!saveCreationTime)
      throw Error("gear did not have a creation time, create one");
  } catch {
    saveCreationTime = currentTime;
  }
  var saveUpdateTime = currentTime;

  // Create a hash for a "Gear ID" (just in case)
  /*
        cyrb53 (c) 2018 bryc (github.com/bryc)
        License: Public domain. Attribution appreciated.
        A fast and simple 53-bit string hash function with decent collision resistance.
        Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.
    */
  const cyrb53 = function (str, seed = 789432) {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };
  // TODO: Hash based off of userName, projectName, and creation time.
  // TODO: need to safely bring username into the save function
  var saveGearHash;
  try {
    saveGearHash = existingGearData.gearid;

    if (!saveGearHash) {
      throw Error("gear did not have a hash, create one.");
    }
  } catch {
    saveGearHash = cyrb53(saveProject + saveCreationTime.toString());
  }

  var infoToSave = {
    gearid: saveGearHash,
    username: tempUsername, // username will be verified by server check against idToken
    idtoken: idToken,
    refreshtoken: refreshToken,
    project: saveProject,
    gear: saveGear,
    recordtype: recordType,
    calcversion: saveCalcVersion,
    inputstring: saveString,
    creationtime: saveCreationTime,
    updatetime: saveUpdateTime,
  };
  // Save this information locally
  // With new userdata storage, expand the list of existing gears(data) [already grabbed and parsed], add the new one
  // If it already exists, replace it

  var gearIsNew = true;
  if (!existingData) existingData = []; // set to an empty array if falsy
  existingData.forEach((element, index) => {
    if (element.gearid == saveGearHash) {
      // gear ID could be stored as String or Int!
      gearIsNew = false; // This is a replacement, not a near gear
      existingData[index] = infoToSave; // Do the replacement!
    }
  });
  if (gearIsNew) {
    existingData.push(infoToSave);
  }

  const writeDB = async (e, dbEntry) => {
    e.preventDefault();

    try {
      const response = await fetch("/savetodb", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dbEntry),
      });
      const apiResponse = await response.json();

      // Update localStorage with a refreshed IdToken (if provided)
      if (apiResponse.newIdToken) {
        // We've received a refreshed token from our call!
        // Update saved Token
        tempUserInfo["idToken"] = apiResponse.newIdToken;
        localStorage.setItem("currentUser", JSON.stringify(tempUserInfo));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Only update record if user is paid! (level >0)

  var userlevel = await getUserLevel(tempUserInfo);

  if (userlevel.usermaxlevel > 0) {
    localStorage.setItem("userdata", JSON.stringify(existingData)); // Update the saved record

    // Save this information to the Database!
    writeDB(event, infoToSave);
    return <div className="save-message error">Saved!</div>;
  } else {
    return (
      <div className="save-message error">
        Saved designs are only available for paid plans.
      </div>
    );
  }
}
