import React from "react";

import { saveInputs } from "../calculator-ui/gear-save";
import { LoadSavedGear } from "../calculator-ui/gear-load";
import { useOutletContext } from "react-router-dom";
import {
  getProjectNameFromID,
  resizeTextAnswer,
  formatDate,
  logQA,
} from "./helpers";
import {
  UnitsRadio,
  DPModuleInput,
  DecimalPlacesSelect,
  ButtonBlockTextArea,
  SaveButtonBlock,
} from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorMOP({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");
  const [project, setProject] = useOutletContext(); // Grab current active project from Outlet (in root.js)
  const [saveMessage, setSaveMessage] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  function clearInputs(event) {
    document.getElementById("input-numteeth").value = "";
    document.getElementById("input-module").value = "";
    document.getElementById("input-pangle").value = "";
    document.getElementById("input-helical").value = "";
    document.getElementById("input-shift").value = "";
    document.getElementById("input-pindia").value = "";
    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  async function saveInputsHandler(event) {
    // Handle saving (with any calculator-specific details)
    // Set the record type to 'single' for one gear
    // Loading the dropdown should match this record type

    // Get project name
    var saveInputResponse = await saveInputs(
      event,
      "single",
      project.project,
      false
    );
    setSaveMessage(saveInputResponse);
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }
    var numTeeth = document.getElementById("input-numteeth").value;
    var module = document.getElementById("input-module").value;
    var pressureAngle = document.getElementById("input-pangle").value;
    var pinDiameter = document.getElementById("input-pindia").value;
    var Xemax = document.getElementById("input-shift").value;
    var beta = document.getElementById("input-helical").value;

    var gearType;
    if (document.getElementsByName("input-type")[0].checked) {
      gearType = "External";
    } else {
      gearType = "internal";
    }

    logQA({
      page: "GCSmop",
      numteeth: numTeeth,
      module: module,
      pangle: pressureAngle,
      pindia: pinDiameter,
      param5: Xemax,
      param6: radioValue,
      param7: gearType,
    });

    if (isNaN(Xemax) || Xemax === "") {
      Xemax = 0;
    }

    var errorMessage = "";
    var okToCalc = true;

    if (
      document.getElementsByName("input-type")[0].checked ===
      document.getElementsByName("input-type")[1].checked
    ) {
      //Both Internal and External are the same (checked or unchecked)
      okToCalc = false;
      errorMessage += "Gear must be either Internal or External\n";
    }

    if (
      document.getElementsByName("units")[0].checked ===
      document.getElementsByName("units")[1].checked
    ) {
      //Both metric and inch are the same (checked or unchecked)
      okToCalc = false;
      errorMessage += "Gear must be either Metric or Inch\n";
    }

    if (!(Xemax >= -0.6 && Xemax <= 0.6)) {
      okToCalc = false;
      errorMessage += "Profile Shift must be between -0.6 and 0.6\n";
    }
    if (!(numTeeth >= -365 && numTeeth <= 365)) {
      okToCalc = false;
      errorMessage += "Number of teeth must be between -365 and 365\n";
    }
    if (!(module >= 0.1 && module <= 256)) {
      okToCalc = false;
      errorMessage += "Module/DP must be between 0.1 and 256\n";
    }
    if (!(pressureAngle >= 1 && pressureAngle <= 45)) {
      okToCalc = false;
      errorMessage += "Pressure Angle must be between 1 and 45 degrees\n";
    }
    if (beta > 45 || beta < 0) {
      okToCalc = false;
      errorMessage += "Helix angle must be between 0 and 45 degrees\n";
    }

    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer(errorMessage);
    }
  }
  function calculateMOW() {
    var radioValue, modWord;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
      modWord = "Diametral Pitch";
    } else {
      radioValue = "mm";
      modWord = "Module";
    }
    var z = parseFloat(document.getElementById("input-numteeth").value);
    var m_n = parseFloat(document.getElementById("input-module").value);
    var m_nInput = m_n; // preserve the initially entered value
    var alpha_n_Degree = parseFloat(
      document.getElementById("input-pangle").value
    );
    var D_d = parseFloat(document.getElementById("input-pindia").value);
    var beta_Degree = parseFloat(
      document.getElementById("input-helical").value
    );
    var alpha_n = (parseFloat(alpha_n_Degree) * Math.PI) / 180;
    var unitWord = "mm";
    var beta = (beta_Degree * Math.PI) / 180;
    var precision = document.getElementById("precision").value;

    if (isNaN(beta) || beta < 0) {
      beta = 0;
      beta_Degree = 0;
    }

    var Xemax = document.getElementById("input-shift").value;
    var Xemin = document.getElementById("input-shift").value;
    if (isNaN(Xemax) || Xemax === "") {
      Xemax = 0;
      Xemin = 0;
    }

    // Change to negative teeth for internal gears
    var typeWord;
    if (document.getElementsByName("input-type")[0].checked === true) {
      z = Math.abs(z);
      typeWord = "External";
    } else if (document.getElementsByName("input-type")[1].checked === true) {
      z = -1 * Math.abs(z);
      typeWord = "Internal";
    }

    if (radioValue === "in") {
      m_n = 25.4 / m_n;
      D_d = D_d * 25.4;
      unitWord = "in";
    }

    var Xefinal = Xemin * m_n; // Profile Shift = Profile Shift Coefficient * Module

    ///BASIC GEAR DIMENSIONS

    var pitchDia = m_n * Math.abs(z); // pitch diameter = module * num teeth
    var transversePitchDiameter = (m_n * Math.abs(z)) / Math.cos(beta); // transverse pitch dia = module * num teeth / cos(helix angle)

    var beta_B = Math.asin(Math.sin(beta) * Math.cos(alpha_n)); //# base helix angle (= 0 for spur gears)
    var alpha_t = Math.atan(Math.tan(alpha_n) / Math.cos(beta)); //# transverse pressure angle at reference diameter

    var d = (Math.abs(z) * m_n) / Math.cos(beta); //# reference diameter
    var d_b = d * Math.cos(alpha_t); //# base diameter

    var S_nmax = m_n * (2 * Xemax * Math.tan(alpha_n) + Math.PI / 2); //# nominal normal circular tooth thickness at reference diameter, max
    //S_nmax = 4.88;
    var eta_bmin =
      (Math.PI - S_nmax / m_n - z * involute(alpha_t)) / Math.abs(z); //# base space width half angle, minimum
    
      let lead;
      if(beta_B > 0){
      lead = Math.PI * z * m_n / Math.tan(beta);
      }
      else {
        lead = 0;
      }
    
    
      /// END BASIC GEAR DIMENSIONS

    /// ASSUMPTIONS
    var d_La =
      z > 0
        ? ((Math.abs(z) + 2) * m_n * 0.99) / Math.cos(beta)
        : Math.abs(z) * m_n + 0.5; //#tip involute limit diameter # ASSUME 99% of the tooth length is involute profile
    var d_Lf = z > 0 ? d_b * 1.01 : (Math.abs(z) + 3.5) * m_n; //# root involute limit diameter # ASSUME the involute profile starts at 1% greater than base diameter. Removes dividing by cos(beta) from d_b on external
    var d_amax = (Math.abs(z) + (z / Math.abs(z)) * 2) * m_n; //# ASSUME tip diameter is (N+2)*Module # used in D_da
    var d_amin = z > 0 ? (Math.abs(z) - 2) * m_n : Math.abs(z) * m_n; //ASSUME tip diameter minimum (for internal gears) is (|N|-2)*Module
    var d_fmax = (Math.abs(z) - (z / Math.abs(z)) * 2.5) * m_n; //# ASSUME root diameter is (N-2.5)*Module # used in D_df
    var d_fmin =
      z > 0
        ? Math.abs(z) * m_n - 2 * ((1 - Xemax) * m_n) + 2 * 2.25 * m_n
        : (Math.abs(z) + 3 + 2 / 3) * m_n; // ASSUME root diameter is . Used in D_df for internal gears

    //d_fmin see: https://khkgears.net/new/gear_knowledge/gear_technical_reference/calculation_gear_dimensions.html
    // d_fmax revised 13Sep21
    // See: https://khkgears.net/new/gear_knowledge/abcs_of_gears-b/basic_gear_terminology_calculation.html
    // z -/+ 2.5 teeth, depending on whether external/internal gear
    //d_La adjusted to account for internal
    //d_amax adjusted to account for internal

    var alpha_KTmax, alpha_KTLf;

    ///  END ASSUMPTIONS
    if (beta !== 0) {
      alpha_KTmax = iterAlpha_KTmax(d_b, d_La, z, eta_bmin, beta_B);
    } else {
      alpha_KTmax =
        Math.tan(Math.acos(d_b / d_La)) + (z / Math.abs(z)) * eta_bmin; //# transverse pressure angle to the center of the max diameter pin
    }

    var D_dmax =
      ((z * m_n * Math.cos(alpha_n)) / Math.cos(beta_B) / Math.cos(beta_B)) *
      (Math.tan(alpha_KTmax) - Math.tan(Math.acos(d_b / d_La))); //# Max Pin Size (in mm)
    //# Minimum pin size

    //# Checking the minimum based on the root involute limit diameter
    var S_nmin = m_n * (2 * Xemin * Math.tan(alpha_n) + Math.PI / 2); //# nominal normal circular tooth thickness at reference diameter, minimum
    //S_nmin = 4.80;
    var eta_bmax =
      (Math.PI - S_nmin / m_n - z * involute(alpha_t)) / Math.abs(z); //# base space width half angle, max

    if (beta !== 0) {
      alpha_KTLf = iterAlpha_KTlf(
        d_b,
        d_La,
        z,
        eta_bmin,
        beta_B,
        d_Lf,
        eta_bmax
      );
    } else {
      alpha_KTLf =
        Math.tan(Math.acos(d_b / d_Lf)) + (z / Math.abs(z)) * eta_bmax;
    }
    var DdLf =
      ((z * m_n * Math.cos(alpha_n)) / Math.cos(beta_B) / Math.cos(beta_B)) *
      (Math.tan(alpha_KTLf) - Math.tan(Math.acos(d_b / d_Lf)));
    //# Checking the minimum based on pin being tangent at the tip diameter (that the pin protrudes for measurement)
    //#for external gears
    var D_da = iterD_da(z, d_amax, d_amin, d_b, eta_bmax, m_n, alpha_n); //#tip diameter of the gear, maximum
    //# Checking the minimum based on avoiding contact with the root
    var D_df = iterD_df(z, d_fmax, d_fmin, d_b, eta_bmax, m_n, alpha_n);

    //# Determining the actual minimum usable diameter
    var D_dmin = Math.max(DdLf, D_da, D_df);

    // CALCULATE DIA FOR PITCH INTERSECTION
    var d_y = (Math.abs(z) * m_n) / Math.cos(beta);
    var eta_bavg =
      (Math.PI - (S_nmax + S_nmin) / (2 * m_n) - z * involute(alpha_t)) /
      Math.abs(z);

    var alpha_ktdy;
    if (beta !== 0) {
      alpha_ktdy = iterAlpha_KTdy(d_b, d_y, z, eta_bavg, beta_B);
    } else {
      alpha_ktdy =
        Math.tan(Math.acos(d_b / d_y)) + (z * eta_bavg) / Math.abs(z);
    }
    var D_ddy =
      ((z * m_n * Math.cos(alpha_n)) / Math.cos(beta_B) / Math.cos(beta_B)) *
      (Math.tan(alpha_ktdy) - Math.tan(Math.acos(d_b / d_y)));

    // Force pin min and max to be min and max
    var D_dmaxTemp = D_dmax;
    var D_dminTemp = D_dmin;
    D_dmax = Math.max(D_dmaxTemp, D_dminTemp);
    D_dmin = Math.min(D_dmaxTemp, D_dminTemp);

    //# Make sure that the selected pin is in range!
    var validPin, d_k2max;
    if (true && D_d < D_dmax && D_d > D_dmin) {
      validPin = true;
      if (z % 2 === 0) {
        //# compensate for odd/even gears
        //d_k2max = 1.1;
        d_k2max =
          d_kmax(D_d, d_b, S_nmax, alpha_n, z, m_n, alpha_t) +
          (z / Math.abs(z)) * D_d;
      } else {
        //d_k2max = 0.9;
        d_k2max =
          d_kmax(D_d, d_b, S_nmax, alpha_n, z, m_n, alpha_t) *
            Math.cos(Math.PI / 2 / z) +
          (z / Math.abs(z)) * D_d;
      }
    } else {
      d_k2max = "Pin is out of range";
      validPin = false;
    }

    if (radioValue === "in") {
      D_d = D_d / 25.4;
    }

    var textToWrite, D_dword;

    var displayProjectName = getProjectNameFromID(project.project);
    // if the user is on "Free" tier, the gearname box won't appear and it will crash
    // default to blank if the input is not shown
    var displayGearName;
    if(document.getElementById("input-gearname")){
      displayGearName = document.getElementById("input-gearname").value
      ? document.getElementById("input-gearname").value
      : "";
    }
    else {
      displayGearName = ""; 
    }

    if (validPin === true) {
      textToWrite = `Inputs
Number of Teeth:\t${Math.abs(z)}
Gear Type:\t${typeWord}
${modWord}:\t${m_nInput}
Pressure Angle:\t${alpha_n_Degree}\tdegrees
Helix  Angle:\t${beta_Degree}\tdegrees
Pin Diameter:\t${D_d.toFixed(precision)}\t${unitWord}
Profile Shift Coefficient:\t${Xemin}\tunitless

Outputs
Measurement over Pins:\t${d_k2max.toFixed(precision)}\tmm\t${(
        d_k2max / 25.4
      ).toFixed(precision)}\tin ${
        D_df === 0 ? "\nNOTE: Modify pins to avoid contacting root" : ""
      }
Minimum Pin Size:\t${D_dmin.toFixed(precision)}\tmm\t${(D_dmin / 25.4).toFixed(
        precision
      )}\tin
Pin Size on Pitch Line:\t${D_ddy.toFixed(precision)}\tmm\t${(
        D_ddy / 25.4
      ).toFixed(precision)}\tin
Maximum Pin Size:\t${D_dmax.toFixed(precision)}\tmm\t${(D_dmax / 25.4).toFixed(
        precision
      )}\tin
Normal Pitch Dia:\t${pitchDia.toFixed(precision)}\tmm\t${(
        pitchDia / 25.4
      ).toFixed(precision)}\tin
Transverse Pitch Dia:\t${transversePitchDiameter.toFixed(
        precision
      )}\tmm\t${(transversePitchDiameter / 25.4).toFixed(precision)}\tin
Profile Shift:\t${Xefinal.toFixed(precision)}\tmm\t${(Xefinal / 25.4).toFixed(
        precision
      )}\tin

\u00A9${new Date().getFullYear()} Evolvent Design,\t${formatDate(new Date())}

Project:\t${displayProjectName}
Gear:\t${displayGearName}
GCS0`;
    } else {
      if (isNaN(D_d)) {
        D_dword = "Unspecified";
      } else {
        D_dword = D_d.toFixed(precision) + "\t" + unitWord;
      }
      textToWrite = `Inputs
Number of Teeth:\t${Math.abs(z)}
Gear Type:\t${typeWord}
${modWord}:\t${m_nInput}
Pressure Angle:\t${alpha_n_Degree}\tdegrees
Helix  Angle:\t${beta_Degree}\tdegrees
Pin Diameter:\t${D_dword}
Profile Shift Coefficient:\t${Xemin}\tunitless

Outputs
Pin diameter invalid; size out of range
Minimum Pin Size:\t${D_dmin.toFixed(precision)}\tmm\t${(D_dmin / 25.4).toFixed(
        precision
      )}\tin
Pin Dia on Pitch Dia:\t${D_ddy.toFixed(precision)}\tmm\t${(
        D_ddy / 25.4
      ).toFixed(precision)}\tin
Maximum Pin Size:\t${D_dmax.toFixed(precision)}\tmm\t${(D_dmax / 25.4).toFixed(
        precision
      )}\tin
Normal Pitch Dia:\t${pitchDia.toFixed(precision)}\tmm\t${(
        pitchDia / 25.4
      ).toFixed(precision)}\tin
Transverse Pitch Dia:\t${transversePitchDiameter.toFixed(
        precision
      )}\tmm\t${(transversePitchDiameter / 25.4).toFixed(precision)}\tin
Profile Shift:\t${Xefinal.toFixed(precision)}\tmm\t${(Xefinal / 25.4).toFixed(
        precision
      )}\tin
Lead:\t${lead.toFixed(precision)}\tmm\t${(
      lead / 25.4
    ).toFixed(precision)}\tin

\u00A9${new Date().getFullYear()} Evolvent Design,\t${formatDate(new Date())}

Project:\t${displayProjectName}
Gear:\t${displayGearName}
GCS0`;
    }
    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  function involute(x) {
    //expects x in radians
    return Math.tan(x) - x;
  }

  function inverseInvolute(x) {
    //returns x in radians
    var maxError = 0.0000000000001;
    var high = Math.PI / 2;
    var low = 0;
    var guess = 0.1;
    var i = 0;
    var maxSteps = 100;

    while (Math.abs(involute(guess) - x) > maxError) {
      i = i + 1;
      if (involute(guess) > x) {
        high = guess;
        guess = (guess + low) / 2;
      } else {
        low = guess;
        guess = (guess + high) / 2;
      }

      if (i >= maxSteps) {
        console.log("D_da failed to converge");
        throw new Error();
      }
    }
    return guess;
  }

  function getD_da(
    internalD_da,
    z,
    d_amax,
    d_amin,
    d_b,
    eta_bmax,
    m_n,
    alpha_n
  ) {
    //  # print "in GetDDA"
    //  # print d_b
    //  # print d_amax
    //  # print D_da
    var d_aMinOrMax;
    if (z > 0) {
      d_aMinOrMax = d_amax;
    } else {
      d_aMinOrMax = d_amin;
    }

    if (d_b / (d_aMinOrMax - (z / Math.abs(z)) * internalD_da) > 1) {
      if (z > 0) {
        internalD_da = 0;
      } else {
        internalD_da = Math.PI * 2;
      }
      //  #raise Exception("DDA failed, acos out of range")
    }
    return (
      (involute(
        Math.acos(d_b / (d_aMinOrMax - (z / Math.abs(z)) * internalD_da))
      ) +
        (z / Math.abs(z)) * eta_bmax) *
      z *
      m_n *
      Math.cos(alpha_n)
    );
  }

  function iterD_da(z, d_amax, d_amin, d_b, eta_bmax, m_n, alpha_n) {
    var guess = m_n * Math.PI;
    var low;
    var high;
    if (z > 0) {
      low = eta_bmax * Math.abs(z) * m_n * Math.cos(alpha_n); //# lower bound for D_da
      high = 0;
    } else {
      high = eta_bmax * Math.abs(z) * m_n * Math.cos(alpha_n); //# lower bound for D_da
      low = 0;
    }

    var j = 0;
    var maxError = 0.0000000000001;
    var maxSteps = 100;

    while (
      Math.abs(
        guess - getD_da(guess, z, d_amax, d_amin, d_b, eta_bmax, m_n, alpha_n)
      ) > maxError
    ) {
      j = j + 1;
      if (
        guess > getD_da(guess, z, d_amax, d_amin, d_b, eta_bmax, m_n, alpha_n)
      ) {
        high = guess;
        guess = (guess + low) / 2;
      } else {
        low = guess;
        guess = (guess + high) / 2;
      }

      if (j >= maxSteps) {
        console.log("D_da failed to converge");
        throw new Error();
      }
    }
    return guess;
  }
  function getD_df(
    internalD_df,
    z,
    d_fmax,
    d_fmin,
    d_b,
    eta_bmax,
    m_n,
    alpha_n
  ) {
    // Calculate D_df using D_df

    var d_fMinOrMax;
    if (z > 0) {
      d_fMinOrMax = d_fmax;
    } else {
      d_fMinOrMax = d_fmin;
    }

    return (
      (involute(
        Math.acos(d_b / (d_fMinOrMax + (z / Math.abs(z)) * internalD_df))
      ) +
        (z / Math.abs(z)) * eta_bmax) *
      z *
      m_n *
      Math.cos(alpha_n)
    );
  }

  function iterD_df(z, d_fmax, d_fmin, d_b, eta_bmax, m_n, alpha_n) {
    // Will return 0 if fails to converge (e.g. the pin needs to be modified to avoid contacting the root)

    var guess = (m_n * Math.PI) / 2;
    var low;
    var high;
    if (z > 0) {
      // set bounds based on internal/external gears
      low = eta_bmax * Math.abs(z) * m_n * Math.cos(alpha_n);
      high = 0;
    } else {
      high = eta_bmax * Math.abs(z) * m_n * Math.cos(alpha_n);
      low = 0;
    }
    var maxError = 0.0000000000001;
    var maxSteps = 100;
    var steps = 0;
    while (
      steps < maxSteps &&
      Math.abs(
        guess - getD_df(guess, z, d_fmax, d_fmin, d_b, eta_bmax, m_n, alpha_n)
      ) > maxError
    ) {
      steps = steps + 1;

      if (
        guess > getD_df(guess, z, d_fmax, d_fmin, d_b, eta_bmax, m_n, alpha_n)
      ) {
        high = guess;
        guess = (guess + low) / 2;
      } else {
        low = guess;
        guess = (guess + high) / 2;
      }
      if (steps >= maxSteps) {
        console.log("D_df failed to converge");
        // Most likely on internal gear (e.g. -20 teeth, 20PA, 1Mod)
        // Note that the pin would need to be modified in order to avoid contacting the root
        return 0;
        //throw new Error();
      }
    }
    return guess;
  }

  function d_kmax(internalD_d, d_b, S_nmax, alpha_n, z, m_n, alpha_t) {
    return (
      d_b /
      Math.cos(
        inverseInvolute(
          (S_nmax + internalD_d / Math.cos(alpha_n)) / (z * m_n) +
            involute(alpha_t) -
            Math.PI / z
        )
      )
    );
  }

  // function d_kmin(internalD_d, d_b, S_nmin, alpha_n, z, m_n, alpha_t){
  //   //console.log("calculating d_kmin");
  //   console.log(d_b/(Math.cos(inverseInvolute((S_nmin+internalD_d/Math.cos(alpha_n))/(z*m_n)+involute(alpha_t)-Math.PI/z))));
  //   return d_b/(Math.cos(inverseInvolute((S_nmin+internalD_d/Math.cos(alpha_n))/(z*m_n)+involute(alpha_t)-Math.PI/z)))
  // }

  // NEW for Helical Gears

  function iterAlpha_KTmax(d_b, d_La, z, eta_bmin, beta_B) {
    var guess = Math.tan(Math.acos(d_b / d_La)) + (z / Math.abs(z)) * eta_bmin;
    guess = guess ? guess : (z / Math.abs(z)) * eta_bmin;
    var error = 1;

    var j = 0;
    var maxError = 0.0000000000001;
    var maxSteps = 100;

    while (Math.abs(error) > maxError) {
      j = j + 1;

      error =
        Math.tan(Math.acos(d_b / d_La)) -
        involute(guess) * Math.sin(beta_B) * Math.sin(beta_B) +
        (z / Math.abs(z)) * eta_bmin * Math.cos(beta_B) * Math.cos(beta_B) -
        guess;
      guess =
        guess +
        error /
          (Math.cos(beta_B) * Math.cos(beta_B) +
            Math.sin(beta_B) / (Math.cos(guess) * Math.cos(guess)));

      if (j >= maxSteps) {
        console.log("Alpha_KTmax failed to converge");
        throw new Error();
      }
    }
    return guess;
  }

  function iterAlpha_KTlf(d_b, d_La, z, eta_bmin, beta_B, d_Lf, eta_bmax) {
    var guess = Math.tan(Math.acos(d_b / d_La)) + (z / Math.abs(z)) * eta_bmin;
    guess = guess ? guess : (z / Math.abs(z)) * eta_bmin;
    var error = 1;

    var j = 0;
    var maxError = 0.0000000000001;
    var maxSteps = 100;

    while (Math.abs(error) > maxError) {
      j = j + 1;

      error =
        Math.tan(Math.acos(d_b / d_Lf)) -
        involute(guess) * Math.sin(beta_B) * Math.sin(beta_B) +
        (z / Math.abs(z)) * eta_bmax * Math.cos(beta_B) * Math.cos(beta_B) -
        guess;
      guess =
        guess +
        error /
          (Math.cos(beta_B) * Math.cos(beta_B) +
            Math.sin(beta_B) / (Math.cos(guess) * Math.cos(guess)));

      if (j >= maxSteps) {
        console.log("Alpha_KTlf failed to converge");
        throw new Error();
      }
    }
    return guess;
  }
  function iterAlpha_KTdy(d_b, d_y, z, eta_bavg, beta_B) {
    var guess = Math.tan(Math.acos(d_b / d_y)) + (z / Math.abs(z)) * eta_bavg;
    guess = guess ? guess : (z / Math.abs(z)) * eta_bavg;
    var error = 1;

    var j = 0;
    var maxError = 0.0000000000001;
    var maxSteps = 100;

    while (Math.abs(error) > maxError) {
      j = j + 1;
      error =
        Math.tan(Math.acos(d_b / d_y)) -
        involute(guess) * Math.sin(beta_B) * Math.sin(beta_B) +
        (z / Math.abs(z)) * eta_bavg * Math.cos(beta_B) * Math.cos(beta_B) -
        guess;
      guess =
        guess +
        error /
          (Math.cos(beta_B) * Math.cos(beta_B) +
            Math.sin(beta_B) / (Math.cos(guess) * Math.cos(guess)));

      if (j >= maxSteps) {
        console.log("Alpha_KTdy failed to converge");
        throw new Error();
      }
    }
    return guess;
  }
  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Measurement over Pins</div>
        <p>
          Measurement over Pins (sometimes called 'Wires' for MOW) is one of the
          most convenient ways to measure tooth thickness.
        </p>
        <p>
          The measurement can made with simple and low cost tools, often before
          removal from the machine. Steep contact angles along the tooth profile
          mean that small differences lead to large changes in measurement. The
          range of pins given all protrude beyond the tooth tip of a standard
          gear and can be measured by calipers.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col>
            <LoadSavedGear
              reqRecordType="single"
              project={project}
              setProject={setProject}
              notProjectSpecific={false}
              loadCallback={calculate}
              userLevel={userLevel}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <UnitsRadio />

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-units" className="unitslabel">
                  Type
                </label>
                <div className="radioList">
                  <input
                    className="radioButton"
                    type="radio"
                    id="input-type-ext"
                    value="ext"
                    name="input-type"
                    defaultChecked
                  />
                  <label className="radioLabel" htmlFor="ext">
                    External
                  </label>
                  <br />
                  <input
                    className="radioButton"
                    type="radio"
                    id="input-type-int"
                    value="int"
                    name="input-type"
                  />
                  <label className="radioLabel" htmlFor="int">
                    Internal
                  </label>
                </div>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth">
                  Number of Teeth<span className="required">*</span>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-numteeth"
                  size="25"
                />
              </div>

              <DPModuleInput />

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pange">
                  Pressure Angle (degrees)
                  <span className="required">*</span>
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Common values: <br />
                      20, 14.5, 25
                    </span>
                  </div>
                </label>
                <input className="inputbox" type="number" id="input-pangle" />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-helical">
                  Helical Angle (degrees)
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">Default (Spur): 0</span>
                  </div>
                </label>
                <input
                  defaultValue={0}
                  className="inputbox"
                  type="number"
                  id="input-helical"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-shift">
                  Profile Shift Coefficient
                  <div className="calctooltip">
                    [?]<span className="tooltiptext">Unitless</span>
                  </div>
                </label>
                <input className="inputbox" type="text" id="input-shift" />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pindia">
                  Pin Diameter
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      mm for Module, <br />
                      inch for DP
                    </span>
                  </div>
                </label>
                <input className="inputbox" type="number" id="input-pindia" />
              </div>

              <DecimalPlacesSelect defaultValue={4} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
              {userLevel > 0 && (
                <SaveButtonBlock
                  project={project}
                  setProject={setProject}
                  saveInputsHandler={saveInputsHandler}
                  saveMessage={saveMessage}
                />
              )}
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/mop-1.jpg" fluid />
            <br />
            <Image src="/calc-images/mop-2.jpg" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          The use of MOP for helical gears requires the use of spherical balls
          instead of pins.
        </p>

        <p>
          A good practice is to avoid the minimum and maximum pin diameters, we
          prefer to use a convenient pin near the average of these numbers.
          Making the measurement across the gear in several different axes can
          help to identify runout.
        </p>
        <p>
          The measurement for odd tooth gears is along the axis between the two
          pins as shown.
        </p>
        <p>
          Tooth thinning does not change the operating pitch diameter, it merely
          thins the teeth allowing more backlash and room for lubricant. The
          thinning is a measurement that is used as a tolerance and should be
          specified on gear drawings.
        </p>
        <p>
          A common way to specify the amount of thinning is through the{" "}
          <span className="term-emphasis">profile shift coefficient</span>.
          Positive values of profile shift coefficient result in thicker teeth
          in both external and internal gears, while negative values result in
          thinner teeth.
        </p>
        <p>
          Multiplying the profile shift coefficient by the module yields a
          numerical profile shift that is helpful for manufacturing.
        </p>
        <Image src="/calc-images/mop-3.png" style={{ maxWidth: "500px" }} />
      </Card>
    </>
  );
}
