import React from "react";

import { saveInputs } from "../calculator-ui/gear-save";
import { LoadSavedGear } from "../calculator-ui/gear-load";
import { useOutletContext } from "react-router-dom";
import {
  getProjectNameFromID,
  resizeTextAnswer,
  formatDate,
  logQA,
} from "./helpers";
import {
  UnitsRadio,
  DPModuleInput,
  DecimalPlacesSelect,
  ButtonBlockTextArea,
  SaveButtonBlock,
  ScrollAnchorTop,
} from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorDimHelixGeo({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");
  const [project, setProject] = useOutletContext(); // Grab current active project from Outlet (in root.js)
  const [saveMessage, setSaveMessage] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  // Common calculator helper functions
  var radioValue;
  var unitWord;
  var ODIDWord = "OD";

  function isPrime(number) {
    var maxTest = parseInt(Math.sqrt(number) + 1);
    var primeNumber = true;
    for (let i = 2; i < maxTest; i++) {
      if (number % i === 0) {
        primeNumber = false;
        break;
      }
    }
    return primeNumber;
  }

  function clearInputs(event) {
    document.getElementById("input-numteeth").value = "";
    document.getElementById("input-numteethgear").value = "";
    document.getElementById("input-pangle").value = "";
    document.getElementById("input-module").value = "";
    document.getElementById("input-shift").value = "";
    document.getElementById("input-helical").value = "";
    document.getElementById("input-face").value = "";
    document.getElementById("input-thinning").value = "";
    document.getElementById("input-tooltipradius").value = "";
    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  async function saveInputsHandler(event) {
    // Handle saving (with any calculator-specific details)
    // Set the record type to 'single' for one gear
    // Loading the dropdown should match this record type

    // Get project name
    var saveInputResponse = await saveInputs(
      event,
      "single",
      project.project,
      false
    );
    setSaveMessage(saveInputResponse);
  }

  // Change the placeholder depending on the user's selection
  var z; // number of teeth, pinion
  var z2; //number of teeth, gear
  var m_n;
  var alpha_n_Degree;
  var alpha_n; //= parseFloat(alpha_n_Degree)*Math.PI/180;
  var precision;
  var shift;
  var beta_m;
  var beta_m_Degree;
  var F_input; // effective face width
  var thinning; // amount of tooth thinning (delta s_n)
  var toolTipRadius; // tool tip radius

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();

    var numTeeth = parseFloat(document.getElementById("input-numteeth").value);
    var module = document.getElementById("input-module").value;
    var pressureAngle = document.getElementById("input-pangle").value;
    precision = document.getElementById("precision").value;
    var beta_m_Degree = document.getElementById("input-helical").value;

    logQA({
      page: "GCSdimspur",
      numteeth: numTeeth,
      module: module,
      pangle: pressureAngle,
      param7: unitWord,
    });

    var errorMessage = "Error";
    var okToCalc = true;

    // if (
    //   document.getElementsByName("input-type")[0].checked ===
    //   document.getElementsByName("input-type")[1].checked
    // ) {
    //   //Both Internal and External are the same (checked or unchecked)
    //   okToCalc = false;
    //   errorMessage = "Gear must be either Internal or External";
    // }

    if (!(numTeeth >= 6 && numTeeth <= 1000)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nNumber of teeth must be between 6 and 1000";
    }
    if (!Number.isInteger(numTeeth)) {
      okToCalc = false;
      errorMessage = "Number of teeth must be an integer";
    }
    if (!(module >= 0.1 && module <= 300)) {
      okToCalc = false;
      errorMessage = errorMessage + "\nModule/DP must be between 0.1 and 300";
    }
    if (!(pressureAngle >= 1 && pressureAngle <= 45)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nPressure Angle must be between 1 and 45 degrees";
    }
    if (!(beta_m_Degree >= 0 && beta_m_Degree <= 90)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nHelix Angle must be between 0 and 90 degrees";
    }
    if (!(precision >= 0 && precision <= 8)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nNumber of Decimal Places must be between 0 and 8";
    }

    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer();
    }
  }


  function involute(x) {
    //expects x in radians
    return Math.tan(x) - x;
  }

  function inverseInvolute(x) {
    //returns x in radians
    var maxError = 0.0000000000001;
    var high = Math.PI / 2;
    var low = 0;
    var guess = 0.1;
    var i = 0;
    var maxSteps = 100;

    while (Math.abs(involute(guess) - x) > maxError) {
      i = i + 1;
      if (involute(guess) > x) {
        high = guess;
        guess = (guess + low) / 2;
      } else {
        low = guess;
        guess = (guess + high) / 2;
      }

      if (i >= maxSteps) {
        console.log("inverseInvolute failed to converge");
        throw new Error();
      }
    }
    return guess;
  }

  function calculateMOW() {
    z = parseFloat(document.getElementById("input-numteeth").value);
    z2 = parseFloat(document.getElementById("input-numteethgear").value);
    m_n = parseFloat(document.getElementById("input-module").value);
    alpha_n_Degree = parseFloat(document.getElementById("input-pangle").value);
    beta_m_Degree = parseFloat(document.getElementById("input-helical").value);
    F_input = parseFloat(document.getElementById("input-face").value);
    thinning = parseFloat(document.getElementById("input-thinning").value);
    toolTipRadius = parseFloat(document.getElementById("input-tooltipradius").value);

    alpha_n = (parseFloat(alpha_n_Degree) * Math.PI) / 180;
    beta_m = (parseFloat(beta_m_Degree) * Math.PI) / 180;
    precision = document.getElementById("precision").value;
    shift = parseFloat(document.getElementById("input-shift").value);

    var m_nInput = m_n;
    let modWord;

    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }

    if (radioValue === "in") {
      m_n = 25.4 / m_n;
      thinning = thinning * 25.4; // convert to mm
      toolTipRadius = toolTipRadius * 25.4; // convert to mm
      unitWord = "inches";
      modWord = "Diametral Pitch";
    } else {
      unitWord = "millimeters";
      modWord = "Module";
    }

    if(isNaN(shift)){
      shift = 0;
    }
    if(isNaN(beta_m) || !beta_m){
      beta_m = 0;
    }
    if(isNaN(thinning) || !thinning){
      thinning = 0;
    }

    var I_both, J_pinion, J_gear,m_F_both;
    var m_p_pinion, m_p_gear;
    var x_min_pinion, x_min_gear;

    for(let i=0; i<=1; i++){
      if(i==1){
        var z_temp = z2;
        z2 = z;
        z = z_temp;
        shift = shift * -1;
      }

    let psi = beta_m; // standard helix angle 
    let phi_n = alpha_n; // standard normal pressure angle
    let delta_s_n = thinning / m_n;

    let m_G = z2/z; // gear ratio
    let R1 = z / (2*Math.cos(psi)); // standard (reference) pitch radius, pinion
    let R2 = R1*m_G; // standard (reference) pitch radius, gear
    let phi = Math.atan(Math.tan(alpha_n)/Math.cos(psi)); // standard transverse pressure angle
    let R_b1 = R1 * Math.cos(phi); // pinion base radius
    let R_b2 = R_b1 * m_G; // gear base radius
    let C_r = (R1 + R2); //operating center distance **
    let phi_r = Math.acos((R_b2 + R_b1) / C_r); // operating transverse pressure angle
    let p_b = 2*Math.PI*R_b1 / z; // transverse base pitch
    let p_N = Math.PI*Math.cos(alpha_n); // normal base pitch
    let psi_b = Math.acos(p_N/p_b); // base helix angle
    let C_6 = C_r*Math.sin(phi_r); // sixth distance along line of action

    // breakaway for undefined term R_02 and R_01
    let outsideDiameterGear = (z2*m_n)/Math.cos(psi) + 2 * m_n;
    let addendumModGear = -1*shift; // shift to negative profile shift for gear
    let outsideDiameterGearEnl = outsideDiameterGear + m_n*2*addendumModGear;
    let R_02 = (outsideDiameterGearEnl / 2) / m_n; // addendum radius of gear
    let outsideDiameter = (z*m_n)/Math.cos(psi) + 2 * m_n;
    let addendumMod = shift; // shift to negative profile shift for gear
    let outsideDiameterEnl = outsideDiameter + m_n*2*addendumMod;
    let R_01 = (outsideDiameterEnl / 2) / m_n; // addendum radius of pinion
    // back to equations

    let C_1 = C_6-(R_02**2-R_b2**2)**0.5; // first distance along line of action
    let C_3 = C_6 / (m_G + 1); // third distance along line of action
    let C_4 = C_1 + p_b; // fourth distance along line of action
    let C_5 = (R_01**2-R_b1**2)**0.5; // fifth distance along line of action
    let C_2 = C_5 - p_b; // second distance along line of action
    let Z = C_5 - C_1; // active length of line of contact

    // move to section 3.1 of AGMA 908
    let m_p = Z / p_b; // transverse contact ratio
    let p_x = Math.PI / Math.sin(psi); // axial pitch

    // F, effective face width, is an input
    let F = F_input / m_n; // effective face width at m_n = 1
    let m_F = F/ p_x; // axial contact ratio
    if(psi ==0 ){ m_F = 0}; // m_F = 0.0 for spur gears

    let L_min;
    let n_r = m_p - Math.floor(m_p); // fractional part of m_p
    let n_a = m_F - Math.floor(m_F); // fractional part of m_F
    if(psi == 0 && m_p <2){
      // for spur gears
      L_min = F;
    }
    else if(n_a <= (1-n_r)){
      // helical gears, case 1
      L_min = (m_p*F-n_a*n_r*p_x)/Math.cos(psi_b);
    }
    else {
      // helical gears, case 2
      L_min=(m_p*F-(1-n_a)*(1-n_r)*p_x)/Math.cos(psi_b);
    }
    let m_N; // load sharing ratio
    if(psi == 0 && m_p < 2){
      m_N = 1;
    }
    else if(m_F <= 1){
      m_N = 1;
    }
    else{
      m_N = F/L_min;
      // Change to match calcs by Robert Errichello
      // Assumption eq. 23
      //m_N = p_N/(0.95*Z);
    }

    let psi_r = Math.atan(Math.tan(psi_b/ Math.cos(phi_r))); // operating helix angle
    let phi_nr = Math.asin(Math.cos(psi_b)*Math.sin(phi_r)); // operating normal pressure angle

    let d = 2*C_r/(m_G +1); // operating pitch diameter of pinion
    
    let R_m1 = 0.5*(R_01+(C_r-R_02)); // mean radius of the pinion

    let rho_m1; // radius of curvature of the pinion profile at the mean radius of the pinion
    let rho_m2; // radius of curvature of the gear profile at the mean radius of the gear
    let C_psi; // helical overlap factor
    let rho_1; // radius of curvature of the pinion profile at point of contact stress calculation
    let rho_2; // radius of curvature of the gear profile at point of contact stress calculation
    if(m_F > 1 && psi >0){
      // conventional helicals
      
      rho_1 = (R_m1**2-R_b1**2)**0.5;
      rho_2 = C_6 -rho_1;
      C_psi = 1;
    }
    else if (m_F <=1 && psi > 0) {
      // LACR helicals
      rho_1 = C_2;
      rho_2 = C_6 - rho_1;
      rho_m1 = (R_m1**2-R_b1**2)**0.5;
      rho_m2 = C_6 - rho_m1;
      C_psi = (1-m_F*(1-rho_m1*rho_m2*Z/(rho_1*rho_2*p_N)))**0.5;
    }

    else {
      // spur gears
      rho_1 = C_2;
      rho_2 = C_6 - rho_1;
      C_psi = 1;
    }

    let I = (Math.cos(phi_r)*C_psi**2)/((1/rho_1+1/rho_2)*d*m_N)

    // AGMA 908, section 5 : Bending Strength Geometry Factor, J
    let n, r_n, r_nb, r_na;

    if(psi > 0){
      // helical gears are considered virtual spur gears
    n = z / (Math.cos(psi)**3); // virtual tooth number
    r_n = n / 2; // standard (reference pitch radius of virtual spur gear)
    r_nb = r_n * Math.cos(phi_n); // virtual base radius
    r_na = r_n + R_01 - R1; // virtual outside radius
    }
    else {
      // spur gears are just spur gears
      n = z;
      r_n = R1;
      r_nb = R_b1;
      r_na = R_01;
    }

    // assume load is at the tip (TODO: it could be shared)

    let r_n2 = r_n*m_G; // standard pitch radius of virtual spur gear
    let r_nb2 = r_nb*m_G; // virtual base radius
    let r_na2 = r_n2+R_02-R2; // virtual outside radius

    let C_n6 = (r_nb2+r_nb)*Math.tan(phi_nr); // sixth distance along line of action of virtual spur
    let C_n1 = (C_n6 -(r_na2**2-r_nb2**2)**0.5); // first distance along line of action of virtual spur gear
    let C_n4 = C_n1 +p_N; // fourth distance along line of action of virtual spur gear


    // TOOTH THINNING NOW AN INPUT
    // using addendum modification as an input!
    let x_g = shift - delta_s_n / (2*Math.tan(phi_n)); // generating rack shift coefficient
    let s_n = Math.PI/2 + 2*x_g*Math.tan(phi_n);

    let involute_phi_np = involute(phi_n)+s_n/(2*r_n); // pressure angle at radius where gear tooth is pointed
    
    // Spur/helical based on Robert Errichello eq 34
    let phi_nW;
    if(psi == 0){
      phi_nW = Math.atan(C_4/r_nb); // Robert eq. 34
    } else {
      phi_nW = Math.atan(((r_na/r_nb)**2-1)**0.5); // pressure angle at load application point
    }
    //let phi_nL = Math.tan(phi_nW) - involute_phi_np; // load at arbitrary point W
    let phi_nL = Math.tan(phi_nW) - Math.tan(phi_n) + phi_n - s_n / n;
    let r_nL = r_nb / Math.cos(phi_nL);

    // ASSUME (TODO): tool tooth count is rack-based (n_c = 10000);
    let n_c = 10000; // tooth number of the tool
    let n_0 = n_c / (Math.cos(psi)**3); // virtual tooth number of the tool
    let r_n0 = n_0 / 2; // standard pitch radius of the virtual tool
    let r_nb0 = r_n0*Math.cos(phi_n); // virtual base radii of tool

    // ASSUME tool does not have a protuberance
    let delta_0 = 0; // no protuberance, tool
    let x_0 = 0; // assumed used tool, near mid-life
    let h_a0 = 1.25; // ASSUME standard tool
    let delta_a0 = 0;

    // eq 5.39
    let rho_a0 = toolTipRadius;
    let r__S_n0 = r_n0 + h_a0 + x_0 - rho_a0;

    let phi_ns = Math.acos(r_nb0/r__S_n0);
    let involute_phi_ns = involute(phi_ns);
    let s_n0 = Math.PI/2 + 2*x_0*Math.tan(phi_n); // reference circular tooth thickness of cutter
    // phi_np0 is the pressure angle where the cutter tooth comes to a point
    let involute_phi_np0 = involute(phi_n) + s_n0/(2*r_n0);
    let lambda_ns = 2*(involute_phi_np0-involute_phi_ns+(delta_a0-rho_a0)/r_nb0); // angle to center "S" of tool tip radius

    // section 5.6

    let involute_phi_n_PP = involute(phi_n) + 2*(x_g+x_0)*Math.tan(phi_n)/(n+n_0); // generating pressure angle
    let phi_n_PP = inverseInvolute(involute_phi_n_PP); // iterate to get inverse involute

    let r_n_PP = r_n*Math.cos(phi_n)/Math.cos(phi_n_PP); // generating pitch radius of virtual spur gear
    let r_n0_PP = r_n0*Math.cos(phi_n)/Math.cos(phi_n_PP); // generating pitch radius of virtual tool

    // TODO: define surface normal (alpha_n) = alpha_n__908
    // Per page 20, alpha_n gets iterated!

    let alpha_n__908 = Math.PI/4;
    let alpha_n_iterations = 0;
    let alpha_n_sequence = [];
    let y, K_s, zeta_nF, h_F;
    while(alpha_n_iterations < 10){

    let mu_n0 = Math.acos(r_n0_PP*Math.cos(alpha_n__908)/r__S_n0)-alpha_n__908;
    K_s = r_n0_PP*Math.sin(alpha_n__908)-r__S_n0*Math.sin(alpha_n__908+mu_n0); // distance frmo pitch point ot point "S"
    let K_F = K_s - rho_a0; // distance from pitch point to point F
    let theta_n0 = mu_n0 - lambda_ns/2 + Math.PI/n_0; // angular displacement of tool
    let theta_n = n_0/n*theta_n0; // gear rotation angle
    let beta_n = alpha_n__908-theta_n; // slope of the line tangent to the fillet at point F
    zeta_nF = r_n_PP*Math.sin(theta_n)+K_F*Math.cos(beta_n); // abscissa of critical point F
    let eta_nF = r_n_PP*Math.cos(theta_n)+K_F*Math.sin(beta_n); // ordinate of critical point F
    h_F = r_nL - eta_nF;

    let y_check = 2*h_F*Math.tan(beta_n)-zeta_nF;
    let y_P = 2*h_F/Math.cos(beta_n)**2 - K_F*Math.sin(beta_n) + (n_0/n) * (r_n0_PP*Math.sin(alpha_n__908)/(r__S_n0*Math.sin(alpha_n__908+mu_n0))-1)
    * (2*zeta_nF*Math.tan(beta_n)-eta_nF-2*h_F/Math.cos(beta_n)**2) - r_n0_PP*(Math.cos(alpha_n__908) - Math.sin(alpha_n__908)/Math.tan(alpha_n__908+mu_n0))
    * (1+Math.sin(beta_n)**2)/Math.cos(beta_n);
    
    alpha_n_sequence.push([alpha_n_iterations,alpha_n__908,y_check, y_P]);
    alpha_n__908 = alpha_n__908 - y_check/y_P;
    alpha_n_iterations++;
    y = y_check;
  }

    // resume at section 5.9
    //let rho_F_P = rho_a0 + K_s**2/((r_n_PP*r_n0_PP*Math.sin(alpha_n__908)/(r_n_PP+r_n0_PP))-K_s); // radius of curvature of the fillet curve
    // minimum  radius of curvature
    let rho_F = rho_a0+(((r_n0_PP-r__S_n0)**2)/((r_n_PP*r_n0_PP/(r_n_PP+r_n0_PP))-(r_n0_PP-r__S_n0))); // minimum radius of curvature of fillet curve

    let C_h;
    let omega = (180/Math.PI)*Math.atan(Math.tan(psi)*Math.sin(phi_n)); // angle in degrees

    if(psi ==0){
      C_h = 1.0;
    }
    else if(psi >0 && m_F < 1){
      C_h = 1.0;
    }
    else {
      C_h = 1/(1-(omega/100*(1-omega/100))**0.5);
    }

    // section 5.11, based on the work of Doland and Broghamer
    let H = Math.round(100*(0.331 - 0.436*phi_n)) / 100;
    let L = Math.round(100*(0.324 - 0.492*phi_n)) / 100;
    let M = Math.round(100*(0.261 + 0.545*phi_n)) / 100;
    let s_F = 2*zeta_nF;
    let K_f = H + (s_F/rho_F)**L * (s_F/h_F)**M; // stress correction factor

    let K_psi; // helix angle factor
    if(psi ==0){
      K_psi = 1.0;
    }
    else if(psi >0 && m_F < 1){
      K_psi = 1.0;
    }
    else {
      K_psi = Math.cos(psi_r)*Math.cos(psi);
    }


    let Y = K_psi / ((Math.cos(phi_nL)/Math.cos(phi_nr))*((6*h_F/(s_F**2 * C_h))-Math.tan(phi_nL)/s_F)); // Tooth form Factor


    let J = Y*C_psi/(K_f*m_N);

    if(i==0){
      I_both = I;
      J_pinion = J;
      m_F_both = m_F;
      m_p_pinion = m_p;
      // minimum profile shift to avoid undercut. AGMA 908-B89 sec 7.7
      x_min_pinion = h_a0 - toolTipRadius*(1-Math.sin(phi_n))-(z/2)*Math.sin(phi_n)**2;
    }
    else {
      J_gear = J;
      z = z2;
      z2 = z_temp;
      shift = shift * -1; // flip it back
      m_p_gear = m_p;
      // minimum profile shift to avoid undercut. AGMA 908-B89 sec. 7.7
      x_min_gear = -1 * z2 * Math.sin(Math.atan(Math.tan(alpha_n)/Math.cos(beta_m)))**2 / (2 * Math.cos(beta_m)) + (h_a0 - toolTipRadius*(1-Math.sin(alpha_n)))/m_n;// minimum profile shift to avoid undercut

    }

  }

    let shiftInput = shift;

    // Rough fix to compile in React
    let gearType;

    let baseCircle = 0;
    let tipDiameter;
    let textToWrite;

    let primeTeeth = isPrime(z);




    // Standard only provides for external gears
    gearType = "External";
    ODIDWord = "OD";
    // if (document.getElementsByName("input-type")[1].checked) {
    //   //check if the gear is internal
    //   gearType = "Internal";
    //   ODIDWord = "ID";
    // } else {
    //   gearType = "External";
    //   ODIDWord = "OD";
    // }



    // Gear Dimensions (per AGMA 1103-H07)
    // Calculations done in mm

    // GCP recreation (for spur):
    // Input: Module, pressure angle, number of teeth, and 2.25 depth system
    // Calculate then revise addendum then reset whole depth

    //let m_t = m_n; // For spur gears, normal module = transverse module
    let alpha_0 = Math.atan(Math.tan(alpha_n)/Math.cos(beta_m)); // profile angle, transverse


    let profileShift = shift * m_n;
    // Tooth proportions
    let addendum = m_n;
    let dedendum = 1.2*m_n + 0.05;
    let workingDepth = 2 * m_n;
    let wholeDepth = 2.2*m_n + 0.05;
    let clearance = 0.2*m_n + 0.05; // standard
    let filletRadius = clearance / (1-Math.sin(alpha_n));
    let toothThickness = Math.PI * m_n /2; // at standard pitch diameter; does not account for any thinning for backlash
    // Formulas
    let circularPitch = Math.PI * m_n; // normal
    let pitchDiameter = z * m_n / Math.cos(beta_m); // standard
    //let outsideDiameter = (z*m_n)/Math.cos(beta_m) + 2 * m_n;
    let rootDiameter = (z * m_n)/Math.cos(beta_m) - 2.4 * m_n - 0.100;

    let enlargementRec = m_n*(1.05 - 0.5*z*Math.sin(alpha_0)*(Math.sin(alpha_0)-Math.cos(alpha_0)*Math.tan(Math.PI*5/180))); // mm
    let enlargementCoeff = enlargementRec / m_n; // proposed based on 5 degree criteria
    let toothThicknessMod = 2*shift*Math.tan(alpha_0);
    //let addendumMod = shift;

    let alpha_t = Math.atan(Math.tan(alpha_n)/Math.cos(beta_m));
    let enlargementCriteria = Math.ceil(2.1*Math.cos(beta_m)/(Math.sin(alpha_t)*(Math.sin(alpha_t)-Math.cos(alpha_t)*Math.tan(5*Math.PI/180))));
    

    // Make adjustments depending on enlargement
    // re-define dimensions for pinion (duplicated because of the loop)
    let outsideDiameter = (z*m_n)/Math.cos(beta_m) + 2 * m_n;
    let addendumMod = shift; // shift to negative profile shift for gear
    let outsideDiameterEnl = outsideDiameter + m_n*2*addendumMod;
    
    let addendumEnl = m_n*(1+shift);
    let normalToothThicknessEnl = m_n*(Math.PI/2 + toothThicknessMod);
    let transToothThicknessEnl = normalToothThicknessEnl / Math.cos(beta_m);
    let rootDiameterEnl = rootDiameter + 2 * m_n * shift; //m_n*(z-2.4+2*shift) - .1;

    let alpha_e = Math.acos(z*m_n*Math.cos(alpha_0)/outsideDiameterEnl);
    let topLandEnl = outsideDiameterEnl*(normalToothThicknessEnl/m_n/z+involute(alpha_0)-involute(alpha_e));

    var undercutThreshold = 2 / Math.sin(alpha_0) / Math.sin(alpha_0);
    var undercutRisk = "No";
    if (z <= undercutThreshold) {
      undercutRisk = "Yes";
    }


    // Check the limitations of AGMA 908-B89
    let resultWarning = false;
    let resultWarningText = "";
    if((m_p_pinion <1 || m_p_gear < 1) && beta_m ==0){
      resultWarning = true;
      resultWarningText += "Spur gear transverse contact ratio must be greater than 1, currently: ";
      resultWarningText += m_p_pinion.toFixed(3);
      resultWarningText += ", ";
      resultWarningText += m_p_gear.toFixed(3);
      resultWarningText += "\n";
    }
    if(m_p_pinion >= 2 || m_p_gear >= 2){
      resultWarning = true;
      resultWarningText += "Transverse contact ratio must be less than 2, currently: ";
      resultWarningText += m_p_pinion.toFixed(3);
      resultWarningText += ", ";
      resultWarningText += m_p_gear.toFixed(3);
      resultWarningText += "\n";
    }
    if(topLandEnl <= 0){
      resultWarning = true;
      resultWarningText += "Teeth cannot be pointed. Top land is currently: ";
      resultWarningText += topLandEnl.toFixed(3);
      resultWarningText += "\n";
    }
    if(beta_m_Degree > 50){
      resultWarning = true;
      resultWarningText += "Helix angle must be less than 50, currently: ";
      resultWarningText += beta_m_Degree;
      resultWarningText += "\n";
    }

    var displayProjectName = getProjectNameFromID(project.project);
    var displayGearName = document.getElementById("input-gearname").value
      ? document.getElementById("input-gearname").value
      : "";

    textToWrite = `Inputs
Number of Teeth:\t${z}
${modWord}:\t${m_nInput}
Pressure Angle:\t${alpha_n_Degree}\tdegrees
Helical Angle :\t${beta_m_Degree}\tdegrees
Profile Shift Coefficient:\t${shift}

Outputs
Entered PSC:  \t${shiftInput.toFixed(precision)}
Proposed PSC: \t${enlargementCoeff > 0 ? enlargementCoeff.toFixed(precision) : "N/A"}
Profile Shift:\t${profileShift.toFixed(precision)}\tmm\t${(
      profileShift / 25.4
    ).toFixed(precision)}\tin

Outside Diameter:\t${outsideDiameterEnl.toFixed(precision)}\tmm\t${(
      outsideDiameterEnl / 25.4
    ).toFixed(precision)}\tin
Pitch Diameter:  \t${pitchDiameter.toFixed(precision)}\tmm\t${(
      pitchDiameter / 25.4
    ).toFixed(precision)}\tin
Root Diameter:   \t${rootDiameterEnl.toFixed(precision)}\tmm\t${(
      rootDiameterEnl / 25.4
    ).toFixed(precision)}\tin

Addendum:            \t${addendumEnl.toFixed(precision)}\tmm\t${(
      addendumEnl / 25.4
    ).toFixed(precision)}\tin
Dedendum:            \t${dedendum.toFixed(precision)}\tmm\t${(
      dedendum / 25.4
    ).toFixed(precision)}\tin
Working Depth:       \t${workingDepth.toFixed(precision)}\tmm\t${(
      workingDepth / 25.4
    ).toFixed(precision)}\tin
Whole Depth:         \t${wholeDepth.toFixed(precision)}\tmm\t${(
      wholeDepth / 25.4
    ).toFixed(precision)}\tin
Circular Pitch:      \t${circularPitch.toFixed(precision)}\tmm\t${(
      circularPitch / 25.4
    ).toFixed(precision)}\tin
Tooth Thickness (n): \t${normalToothThicknessEnl.toFixed(precision)}\tmm\t${(
      normalToothThicknessEnl / 25.4
    ).toFixed(precision)}\tin
Tooth Thickness (t): \t${transToothThicknessEnl.toFixed(precision)}\tmm\t${(
      transToothThicknessEnl / 25.4
    ).toFixed(precision)}\tin
Top Land:            \t${topLandEnl.toFixed(precision)}\tmm\t${(
      topLandEnl / 25.4
    ).toFixed(precision)}\tin
Min. Shift (pinion): \t${x_min_pinion.toFixed(precision)}
Min. Shift (gear):   \t${x_min_gear.toFixed(precision)}

I-Factor:            \t${I_both.toFixed(precision)}
J-Factor (pinion):   \t${J_pinion.toFixed(precision)}
J-Factor (gear):     \t${J_gear.toFixed(precision)}
Axial Contact Ratio: \t${m_F_both.toFixed(precision)}
${resultWarning ? "WARNING:\n" + resultWarningText : ''}
Small Top Land:    \t${topLandEnl < 0.275*m_n ? "Yes" :"No"}
Enlargement (min): \t${enlargementCriteria}
Undercut:          \t${undercutRisk}
Prime:             \t${primeTeeth ? "Yes" : "No"}
\u00A9 ${new Date().getFullYear()} Evolvent Design,\t${formatDate(new Date())}

Project:\t${displayProjectName}
Gear:\t${displayGearName}
GCS0`;

    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">BETA: Geometry factors for Fine Dimensions (Helix)</div>
        <div>

          <span className="term-emphasis">This calculator is in beta and may not be reliable</span>
<br /><br />
          Calculate the geometry of fine-pitch spur gears per <i>AGMA 1103-H07</i>, including the ability to add profile shifts.
          Positive shifts are typically added to pinions to increase strength, and equal, but negative shifts are added to gears in order to maintain the same center distance
          <br /><br />

          <p>The entered profile shift is applied to the pinion, its opposite (-1*shift) is applied to the gear</p>

          <p>Positive profile shifts for pinions are recommended:</p><ul>
            <li>between 11-50 teeth at 14.5º pressure angle</li>
            <li>between 9-23 teeth at 20º pressure angle</li>
            <li>between 8-15 teeth at 25º pressure angle</li>
          </ul>
          <p>
            Flags notify when your gear has a prime number of teeth, or risk of
            undercut in the standard gear tooth profile.
          </p>
        
        </div>
      </Card>

      <Card className="project-card">
        <Row>
          <Col>
            <LoadSavedGear
              reqRecordType="single"
              project={project}
              setProject={setProject}
              notProjectSpecific={false}
              loadCallback={calculate}
              userLevel={userLevel}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <UnitsRadio />
              <div hidden="true">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label className="unitslabel">Type</label>
                  <div>
                    <input
                      className="radioButton"
                      type="radio"
                      id="input-type-ext"
                      value="ext"
                      name="input-type"
                      defaultChecked
                    />
                    <label className="radioLabel" htmlFor="input-type-ext">
                      External
                    </label>
                  </div>
                  <div>
                    <input
                      className="radioButton"
                      type="radio"
                      id="input-type-int"
                      value="int"
                      name="input-type"
                    />
                    <label className="radioLabel" htmlFor="input-type-int">
                      Internal
                    </label>
                  </div>
                </div>
                </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth">
                  Number of Teeth (Pinion)<span className="required">*</span>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-numteeth"
                  size="25"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteethgear">
                  Number of Teeth (Gear)<span className="required">*</span>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-numteethgear"
                  size="25"
                />
              </div>

              <DPModuleInput />

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pangle">
                  Pressure Angle, normal (degrees)<span className="required">*</span>
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Common values: <br />
                      20, 14.5, 25
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-pangle"
                  size="25"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-helical">
                  Helical Angle (degrees)
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">Default (Spur): 0</span>
                  </div>
                </label>
                <input
                  defaultValue={0}
                  className="inputbox"
                  type="number"
                  id="input-helical"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-face">
                  Effective Face Width (F)
                </label>
                <input
                  defaultValue={0}
                  className="inputbox"
                  type="number"
                  id="input-face"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-shift">
                  Profile Shift Coefficient
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Unitless
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-shift"
                  size="25"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-thinning">
                  Tooth Thinning Amount
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      mm or inch
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-thinning"
                  size="25"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-tooltipradius">
                  Tool Tip Radius
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      mm or inch
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-tooltipradius"
                  size="25"
                />
              </div>
              <DecimalPlacesSelect defaultValue={4} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />

              {userLevel > 0 && (
                <SaveButtonBlock
                  project={project}
                  setProject={setProject}
                  saveInputsHandler={saveInputsHandler}
                  saveMessage={saveMessage}
                />
              )}
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/dimensions-spur.png" fluid />
            <br />
            <p>A positive profile shift increases the outer diameter and tooth thickness of this pinion</p>
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          <span className="term-emphasis">Common modules</span> are: 1.25, 1.0, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2
        </p>
        <p>
          <span className="term-emphasis">Base Circle</span> is not a physical
          diameter on the gear, but it is the theoretical diameter from which
          the true involute emanates.
        </p>
        <p>
          <span className="term-emphasis">Enlargement (min)</span> indicates the minimum number of teeth where an enlargement 
          (profile shift) factor is no longer recommended by <i>AGMA 1103-H07</i>
        </p>

        <p>
          <span className="term-emphasis">Small Top Land </span> indicates whether the top land is smaller 
          than the amount (0.275*module) recommended by <i>AGMA 1103-H07</i>
        </p>
        <p>
          <span className="term-emphasis">Undercut</span> flag indicates whether
          an undercut (when the involute profile cuts into the root of the gear
          tooth) is present in the standard (unshifted) gear. This typically occurs on low tooth count gears, but
          is also a function of pressure angle. An undercut can greatly reduce
          the strength of a gear, and should generally be avoided. Additional
          design modifications can also help minimize undercuts.
        </p>
        <p>
          <span className="term-emphasis">Min. Shift</span> is the minimum profile shift for the pinion 
          and gear in order to avoid an undercut
        </p>
        <p>
          <span className="term-emphasis">Prime</span> flag indicates whether
          the tooth count is a prime number.
        </p>
        <p>There are <span className="term-emphasis">additional limitations</span>, per <i>AGMA 908-B89</i>, which are checked automatically:
        <ul>
          <li>Spur gears with transverse contact ratio less than 1</li>
          <li>Spur or helical gears with transverse contact ratio greater than 2</li>
          <li>Teeth are pointed</li>
          <li>The helix angle is greater than 50 degrees</li>
        </ul>
        and which must be checked manually:
        <ul>
          <li>Interference exists between the tips of teeth and root fillets</li>
          <li>Undercut exists in an area above the theoretical start of active profile</li>
          <li>The root profiles are stepped or irregular</li>
          <li>Root fillets are produced by a process other than generating</li>
        </ul>
        </p>
      </Card>
    </>
  );
}
