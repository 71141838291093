import React from "react";

import Card from "react-bootstrap/Card";

export default function UtilityReference() {
  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  return (
    <Card className="project-card">
      <div className="project-name" style={{ marginBottom: "40px" }}>
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        Reference Documents
      </div>
      <a href="/documents/NASA_Overview_83.pdf">Geared Power Transmission Technology, 1983</a>
      <a href="/documents/NASA_Gearing_85.pdf">NASA Gearing, 1985</a>
      <a href="/documents/NASA_Fasteners_90.pdf">NASA Fasteners, 1990</a>
    </Card>
  );
}
